
import {inject} from "@angular/core";
import {CanMatchFn, Router} from "@angular/router";
import { loginType } from "@features/auth/domain/login-type";
import {AuthStore} from "@features/auth/framework/services/auth-store.service";

export const loggedIn: CanMatchFn = () => {
  const authStore = inject(AuthStore);
  const router = inject(Router);
  return authStore.hasTokens() || router.parseUrl("/guest/auth");
};

export function loggedInAs(type: loginType): CanMatchFn {
  return () => {
    const authStore = inject(AuthStore);
    const router = inject(Router);
    const currentLoginType = authStore.snapshotOnly(state => state.loginType);

    return type === currentLoginType || router.parseUrl(`/${currentLoginType}`);
  }
}

export const loggedOut: CanMatchFn = async () => {
  const authStore = inject(AuthStore);
  console.log(`logget out: ${!authStore.hasTokens()}`);

  return !authStore.hasTokens();
};
